@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@charset "UTF-8";
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  background: #FFF;
  font-size: 18px;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.header {
  display: grid;
  grid-template-columns: 16% 62%;
  margin-top: 1vh;
  max-width: 590px;
}

.header .logo {
  margin: 2px 19px 5px 10px;
  width: 3.3em;
  /* border-radius: 50%;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* float: left; */
}

.menu {
  display: flex;
  grid-column-gap: 10px;
  column-gap: 10px;
  padding-top: 1.7vh;
  padding-left: 2px;
  border-bottom: 2px solid #d9d9d9;
  width: 324px;
}

.page {
  display: grid;
  grid-template-rows: 50% 50%;
  grid-gap: 3px;
  cursor: pointer;
}

.page .i {
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}

.header .a {
  color: rgb(51, 49, 49);
  /* color:  rgb(0, 235, 176); */
  text-decoration: none;
  font-size: 12px;
  font-family: Montserrat;
  margin: 5px;
}

.page.two .i {
  font-size: 18px;
}

.page.four .i {
  font-size: 20px;
}

.page.one:hover .a {
  color: rgb(0, 235, 176);
}

.page.one:hover .i {
  color: rgb(0, 235, 176);
}

.page.two:hover .a {
  color: rgb(0, 235, 176);
}

.page.two:hover .i {
  color: rgb(0, 235, 176);
}

.page.three:hover .a {
  color: rgb(0, 235, 176);
}

.page.three:hover .i {
  color: rgb(0, 235, 176);
}

.page.four:hover .a {
  color: rgb(0, 235, 176);
}

.page.four:hover .i {
  color: rgb(0, 235, 176);
}

/*MAGIC ACCOUNT START - container of welcome and accountCreation cards*/

.magicAccount {
  position: relative;
  display: flex;
  /* flex-direction: row; */
  /*TODO: Change to row again*/
  flex-direction: column;
  justify-content: center;
  /* align-items: center;    */
  /* width: 100%;    */
  /* margin-left: 1em;
  margin-bottom: 5em;
  margin-top: 2.9em; */
}

.welcomeAccount {
  width: 346px;
  height: 615px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  /* margin-left: 3em; */
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  transition: 0.6s;
}

.backDiv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #32D5D8;
}

.backDiv h1 {
  position: relative;
  margin-top: 1.3rem;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 4rem;
  color: #EEEEEE;
}

.backDiv h5 {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #EEEEEE
}

.welcomeAccount img {
  width: 224px;
  align-content: center;
  margin: 0 auto;
}

.buttonDiv {
  position: absolute;
  bottom: 1.2vh;
  width: 64vw;
  max-width: 346px;
  height: 8vh;
  margin-top: -3vh;
}

.buttonDiv button {
  width: 60%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #E8EF0F, #E8EF0F, #E8EF0F);
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  /* font-family: 'Poppins', sans-serif; */
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
  color: rgb(73, 66, 66);
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.welcomeBtn:hover {
  background-position: right;
  transform: scale(1.03)
}

.accountCreation {
  width: 346px;
  height: 615px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  /* margin-left: 3em; */
  /* margin-top: 1em; */
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  transition: 0.6s;
}

.accountContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc( 100vw - 40px );
  max-width: 300px;
  height: 468px;
  overflow-x: hidden;
}

/* .accountContainer img{

} */

.accountContainer h2 {
  margin: 15px 0;
  margin-top: 0.6em;
  color: #333;
  text-transform: uppercase;
  font-size: 2.5rem;
}

.accountInput {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  width: 300px;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: .3s;
}

.accountInput>div {
  position: relative;
  height: 45px;
}

.accountInput>div>h5 {
  position: absolute;
  left: 10px;
  top: -14%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: .3s;
}

.accountInput::before, .accountInput::after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #38d39f;
  transition: .4s;
}

.accountInput::before {
  right: 50%;
}

.accountInput::after {
  left: 50%;
}

.accountInput.focus:before, .accountInput.focus:after {
  width: 50%;
}

.accountInput.focus>div>h5 {
  top: -30px;
  font-size: 15px;
  color: rgb(0, 235, 176);
}

.accountInput.focus>.i {
  color: #38d39f;
}

.accountInput>div>input {
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.1rem;
  color: #555;
  font-family: 'poppins', sans-serif;
}

.accountBtn {
  display: block;
  width: 70%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  margin: 4.4em 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.accountBtn:hover {
  background-position: right;
}

.hideCards {
  display: none;
}

.alcancia {
  height: 100px;
  margin-top: -4em;
}

.fundingBtn {
  display: block;
  width: 45%;
  height: 45px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  /* background-color: rgb(106, 120, 202); */
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  /* text-transform: uppercase; */
  margin: 2em 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.fundingButtonDiv {
  display: flex;
  grid-column-gap: 27px;
  column-gap: 27px;
}

.handleAccountBtn {
  position: relative;
  bottom: 1.3em;
  height: 52px;
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: none;
  background-color: #34f2c6;
  /* background-size: 200%; */
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  /* text-transform: uppercase; */
  margin: 3em 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.handleAccountTittle {
  size: 3em;
}

.handleAccountButton {
  display: grid;
  grid-template-columns: 45% 19% 19%;
  grid-row-gap: 30px;
  grid-column-gap: 15px;
  margin-top: 1.5em;
  overflow: scroll;
  font-size: 0.8em;
}

.handleAccountButton button {
  border-radius: 2px;
  outline: none;
  border: none;
  background-color: #00B0FF;
  background-size: 200%;
  /* font-size: 1.2rem; */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  /* text-transform: uppercase; */
  /* margin: 4.4em 0;
  margin-bottom: 0; */
  /* margin-left: auto;
  margin-right: auto; */
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.accountContainer h3 {
  margin-bottom: 0.2em;
  font-size: 1.8em;
  margin-top: 1em;
}

/*Funding design*/

.baseAsset {
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Poppins', sans-serif;
}

/*ChooseTx design*/

.chooseTx {
  display: flex;
  flex-direction: column;
  width: 64vw;
  height: 61vh;
  max-width: 346px;
  text-align: center;
  position: relative;
  margin-left: 3em;
  margin-top: 1em;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  transition: 0.6s;
}

.chooseButtonsDiv {
  display: grid;
  grid-template-columns: 34% 60%;
  grid-template-rows: 33% 33% 33%;
  grid-row-gap: 23px;
  grid-column-gap: 15px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.chooseButton {
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #6C63FF;
  background-size: 200%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* font-size: 1.2rem; */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

.chooseTittle {
  font-size: 1.5em;
  margin-top: 0.8em;
  margin-bottom: 1em;
}

.chooseExplanation {
  font-size: 13px;
  text-align: left;
}

/*Choose Escrow design*/

.chooseEscrowContainer {
  width: 64vw;
  max-width: 346px;
  height: 61vh;
  align-items: center;
  text-align: center;
  position: relative;
  margin-left: 3em;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  transition: 0.6s;
}

.chooseEscrowContainer img {
  width: 37vw;
  max-width: 224px;
  /* position: absolute; */
  bottom: 12vh;
  left: 11vw;
  left: 65.4px;
}

.escrowButtonDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 14px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1.5em;
}

.escrowBtn {
  height: 7vh;
  border-radius: 10px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: .5s;
  text-align: center;
  margin-top: 1em;
}

/*Handle escrow design*/

#escrowTittle {
  font-size: 2em;
}

/*Choose Welcome Cards: payment and accounts*/

.buttonDivChoose {
  /* display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 14px; */
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1em;
}

.welcomeBtn {
  height: 52px;
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: none;
  background-color: #34f2c6;
  /* background-size: 200%; */
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: .5s;
  text-align: center;
  margin-top: 1em;
}

.secondaryBtn {
  background-color: #F0ECFC;
  color: #34f2c6;
}

/**********************************
CSS for the admin handler component
***********************************/

.adminBigContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 200px;
  margin-top: 0.2em;
  margin-left: 1rem;
}

.adminDiagram {
  display: grid;
  grid-gap: 8px;
  height: 70vh;
  width: 427px;
  margin-top: 1em;
  margin-left: 1.5em;
  /* background-color: blue; */
}

/* .adminDiagram > Div{
  background-color: black;
} */

.adminDiagram p {
  align-self: end;
  margin: 5px;
  font-size: 12px;
}

.ilpBtn {
  width: 90px;
  height: 50px;
  background-color: #F49F1C;
  background-color: #6DD47E;
  box-shadow: 0 9px #95a5a6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
  color: #000080;
  color: #293250;
  /*Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  border: none;
}

.ilpBtn:hover {
  background: #fff;
  font-weight: bold;
  box-shadow: 0px 6px 14px 9px #97B1BF;
  box-shadow: 0px 2px 10px 5px rgb(223, 213, 213);
  color: rgb(0, 0, 0);
}

.ilpBtn:active {
  transform: translateY(1px);
  transition: 0s;
  background-color: #fff;
}

.ledgerBtn {
  width: 90px;
  height: 50px;
  background-color: #FFD55A;
  box-shadow: 0 9px #95a5a6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
  color: #293250;
  /*Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  border: none;
}

.ledgerBtn:hover {
  background: #fff;
  box-shadow: 0px 6px 14px 9px #97B1BF;
  box-shadow: 0px 2px 10px 5px rgb(223, 213, 213);
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.ledgerBtn:active {
  transform: translateY(1px);
  transition: 0s;
  background-color: #fff;
}

.cashIn {
  grid-row-start: 1;
  grid-row-end: 3;
  display: grid;
  grid-template-rows: 55% 22%;
  justify-items: center;
}

.cashOut {
  grid-row-start: 1;
  grid-row-end: 3;
  display: grid;
  grid-template-rows: 55% 22%;
  justify-items: center;
}

/* .cashBtn{
  width:100px
} */

/* .cashP{
  align-self: end;
  margin:5px;
  font-size: 12px;
} */

.wupoConnector {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  display: grid;
  grid-template-rows: 20% 22%;
  justify-items: center;
}

.adminSelectContainer {
  width: 64vw;
  max-width: 346px;
  height: 25vh;
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;
  margin-left: 3em;
  transition: 0.6s;
}

.selectDesign {
  display: grid;
  height: 3em;
  width: 14em;
  font-size: 18px;
  font-family: 'Montserrat';
  text-align-last: center;
  background-color: #FFD55A;
  box-shadow: 0 9px #95a5a6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  color: #293259;
}

.optionsDesign {
  justify-self: center;
}

.xrpConnector {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: start;
  padding-left: 55px;
}

.ethConnector {
  grid-column-start: 3;
  grid-column-end: 5;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: end;
  padding-right: 55px;
}

.wupoXrpAccount {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: end;
  padding-right: 20px;
}

.wupoEthAccount {
  grid-column-start: 3;
  grid-column-end: 5;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: start;
  padding-left: 20px;
}

.clientXrpAccount {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: end;
  padding-right: 20px;
}

.clientEthAccount {
  grid-column-start: 3;
  grid-column-end: 5;
  display: grid;
  grid-template-rows: 40% 22%;
  justify-items: start;
  padding-left: 20px;
}

.adminResult {
  height: 100%;
}

/***********************************/

/**********************************
CSS for the admin profit handler
***********************************/

.proftitContainer {
  overflow: scroll;
  width: 300px;
  height: 61vh;
}

.profitTittle {
  text-align: center;
  top: 0.5em;
  margin: 15px 0;
  margin-top: 0.6em;
  color: #333;
  text-transform: uppercase;
  font-size: 2rem;
  justify-self: center;
  align-self: center;
}

.profitNetBalance {
  justify-self: end;
  padding-right: 1em;
}

.profitNetAsset {
  justify-self: start;
  padding-left: 1em;
}

.profitButton {
  height: 7vh;
  width: 20vw;
  border-radius: 10px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #000080, #000080, #000080);
  background-size: 200%;
  font-size: 1.2rem;
  color: #F49F1C;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: .5s;
  text-align: center;
  margin-top: 0.3em;
}

.buttonProfitGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  margin-top: 0.7em;
}

.totalSpreadDesign {
  display: grid;
  grid-template-columns: 69% 20%;
  height: 2.5em;
  font-weight: bold;
  font-size: 20px;
}

.profitGridViewer {
  display: grid;
  grid-template-columns: 33% 36% 20%;
  font-size: 17px;
}

/**********************************
CSS for the admin profit Change Handler
***********************************/

.profitChangeInputContainer {
  position: relative;
  right: 14px;
  display: grid;
  grid-template-columns: 7% 93%;
  width: 84px;
  height: 24px;
  margin: 12px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.profitChangeIcon {
  color: #d9d9d9;
  width: 1em;
}

.profitChangeInputDiv {
  position: relative;
  height: 45px;
}

.profitChangeH5 {
  position: absolute;
  left: 10px;
  top: -14%;
  transform: translateY(-50%);
  color: #999;
  font-size: 10px;
  transition: .3s;
}

.profitChangeInput {
  position: absolute;
  left: 10px;
  bottom: 16px;
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 14px;
  color: #38d39f;
  font-family: 'poppins', sans-serif;
}

/*Input effect*/

.profitChangeInputContainer::before, .profitChangeInputContainer::after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #38d39f;
  transition: .4s;
}

.profitChangeInputContainer::before {
  right: 50%;
}

.profitChangeInputContainer::after {
  left: 50%;
}

.profitChangeInputContainer.focus:before, .profitChangeInputContainer.focus:after {
  width: 50%;
}

.profitChangeInputContainer.focus>div>h5 {
  top: -30px;
  font-size: 15px;
  color: rgb(0, 235, 176);
}

.profitChangeInputContainer.focus>.profitChangeIcon {
  color: #38d39f;
}

/**********************************/

/**********************************
CSS for the admin Operational Handler
***********************************/

.OperationalTittle {
  text-align: center;
  top: 0.5em;
  margin: 15px 0;
  margin-top: 0.6em;
  color: #333;
  text-transform: uppercase;
  font-size: 1.9rem;
  justify-self: center;
  align-self: center;
}

.operationWalletBalance {
  padding-top: 2em;
  font-size: 1.6em;
  color: #38d39f;
  font-weight: bold;
}

/*xrpl connector css deisgn*/

.balanceSheetDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  font-size: 16px;
}

.balanceSheetBtn {
  display: block;
  width: 70%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #FFD55A, #FFD55A, #FFD55A);
  background-size: 200%;
  font-size: 1.2rem;
  color: #293250;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  /* margin: 4.4em 0; */
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: .5s;
  text-align: center;
}

/*REGISTER DESIGN*/

.magicRegister {
  position: relative;
  display: flex;
  /* flex-direction: row; */
  /*TODO: Change to row again*/
  flex-direction: column;
  justify-content: center;
  /* align-items: center;    */
  /* width: 400px;    */
  /* margin-left: 1em;
  margin-bottom: 5em;
  margin-top: 1.8em; */
}

.wupoRegister {
  color: #38d39f;
  width: 64vw;
  margin-top: 0;
  margin-bottom: 0;
  align-self: start;
  padding-left: 3.5em;
}

/*Register Rendering*/

.chooseAuth {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1.3em;
  left: 3.1em;
  width: 50vw;
}

.QRcode {
  display: flex;
  justify-content: center;
  margin-bottom: 5em;
}

.QRCCodeLabel {
  margin: 15px 0;
  margin-top: 2.9em;
  margin-bottom: 0;
  color: rgb(93, 162, 226);
  text-transform: uppercase;
  font-size: 2.2rem;
}

.authBtn {
  width: 100%;
  height: 52px;
  border-radius: 12px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: .5s;
  margin-top: 1em;
}

.passAuth {
  display: flex;
  align-items: center;
  position: relative;
  /* bottom: 2.7em; */
  /* left: 2em; */
  width: 100%;
  margin-top: 3em;
}

.QRimage {
  height: 300px;
  margin-bottom: 2.5em;
}

/*Transfer cost in the logged component*/

.transferCost {
  color: black;
  font-weight: bold;
}

.swal-modal {
  max-width: 327px;
  border-radius: 24px;
  /* padding: 4px; */
}

.swal-modal .swal-footer .swal-button-container {
  margin: 5px 0;
  width: 100%;
}

.swal-modal .swal-footer .swal-button-container .swal-button {
  width: 100%;
  height: 52px;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--confirm {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--info {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--admin {
  background-color: #34f2c6;
}

.paymentQR{
  
  height: 600px;
  margin-top: 0em;
  margin-left: -37px;
  margin-right: 9em;
  
}

/*RESPONSIVENESS*/

@media(max-width: 346px) {
  .header {
    grid-template-columns: 13% 75%;
  }
  .menu {
    grid-column-gap: 4px;
    column-gap: 4px;
    padding-left: 0;
    width: 269px;
  }
  .header .logo {
    width: 25px;
  }
  .menu i {
    font-size: 16px;
  }
  .menu a {
    font-size: 10.3px;
  }
  .backDiv h1 {
    font-size: 2.8rem;
  }
  .backDiv h5 {
    font-size: 1.35rem;
  }
  .welcomeAccount {
    /* margin-left: 1.8em; */
  }
  .welcomeAccount img {
    max-width: 64vw;
    left: 0;
  }
  .backDiv {
    height: 20vh;
  }
  /* Account creation responsiveness*/
  .accountCreation {
    /* margin-left: 1.8em; */
  }
  .accountInput {
    /* width: 53vw;
    margin: 25px; */
  }
  .welcomeBtn {
    font-size: 0.8rem
  }
  /*Responsivensess Wupealo functionality*/
  .chooseButton {
    font-size: 0.5rem;
  }
  .chooseExplanation {
    font-size: 0.65rem;
  }
  /* Resp. Account balance functionality*/
  .handleAccountButton button {
    font-size: 0.6rem;
    /*Profit responsive*/
  }
  .profitButton {
    font-size: 0.8em;
  }
  .balanceSheetBtn {
    height: 39px;
    margin-bottom: 10px;
  }
  .adminDiagram {
    display: grid;
    grid-gap: 8px;
    height: 70vh;
    width: 335px;
    margin-top: 1em;
    margin-left: 0.3em
  }
  @media(max-height: 650px) {
    .chooseTittle {
      font-size: 1em;
      margin-bottom: 0em;
    }
    .backDiv {
      height: 18vh;
    }
    .backDiv h1 {
      margin-top: 1rem;
      font-size: 2.1rem;
    }
    .backDiv h5 {
      font-size: 1.2rem;
      margin-top: 0.18rem;
    }
    .welcomeAccount img {
      width: 49vw;
      left: 27px;
    }
    .handleAccountButton button {
      font-size: 0.6rem;
    }
    .chooseButtonsDiv {
      grid-row-gap: 15px;
    }
    .accountBtn {
      margin-top: 2.4em;
    }
    .accountContainer h3 {
      font-size: 1.3em;
    }
    .handleAccountBtn {
      font-size: 1rem;
    }
    .fundingButtonDiv {
      grid-column-gap: 0px;
      column-gap: 0px;
    }
    .fundingBtn {
      width: 38%;
      font-size: 0.9rem;
    }
  }
}

@media(max-width: 346px) {
  .magicAccount {
    width: 100%;
  }
  .welcomeAccount {
    width: 100%;
    border-radius: 0px;
  }
  .backDiv {
    border-radius: 0;
  }
  .welcomeAccount, .accountCreation {
    height: 100vh;
  }
}

@media(max-height: 615px) {
  .welcomeAccount, .accountCreation {
    height: 100vh;
  }
}

/*# sourceMappingURL=accountStyle.css.map */
@charset "UTF-8";
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  background: #FFF;
  font-size: 18px;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* swal-modal */

.swal-modal {
  max-width: 327px;
  border-radius: 24px;
}

.swal-modal .swal-footer .swal-button-container {
  margin: 5px 0;
  width: 100%;
}

.swal-modal .swal-footer .swal-button-container .swal-button {
  width: 100%;
  height: 52px;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--confirm {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--info {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--admin {
  background-color: #34f2c6;
}
