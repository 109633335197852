@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  background: #FFF;
  font-size: 18px;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* swal-modal */

.swal-modal {
  max-width: 327px;
  border-radius: 24px;
}

.swal-modal .swal-footer .swal-button-container {
  margin: 5px 0;
  width: 100%;
}

.swal-modal .swal-footer .swal-button-container .swal-button {
  width: 100%;
  height: 52px;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--confirm {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--info {
  background-color: #34f2c6;
}

.swal-modal .swal-footer .swal-button-container .swal-button.swal-button--admin {
  background-color: #34f2c6;
}